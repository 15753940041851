import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AdminHome = () => {
  return (
    <>
    </>
  )
};

export default AdminHome;
